// eslint-disable-next-line no-redeclare,no-unused-vars
function getRefSettings(value, object) {
  var key, keySub, keySub2;
  [key, keySub, keySub2] = value.split('.');
  var objSub = fields[object][key].reference;
  var settings = fields[objSub][keySub];
  if (keySub2) settings = fields[settings.reference][keySub2];
  if (!settings) settings = {};
  return settings;
}
